import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MDReactComponent from "markdown-react-js"

// @Material UI
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"

import { LikeButton, FavButton } from "../components/actions/Actions"

export default function Tpl({ data }) {
  if (!data) {
    return (
      <Paper>
        <h2>...</h2>
      </Paper>
    )
  }

  let { title, content, date, image, strapiId, fields } = data.strapiBasic
  if (fields && fields.slug) {
    // window.history.pushState("", "", '/actualidad/' + fields.slug); // @todo
  }
  return (
    <Layout section={title}>
      <SEO title={title} />
      <Grid container spacing={3} className="page-detail basic-detail">
        <Grid item xs={12} sm={7} lg={9} className="page-detail-content">
          <Typography
            className="page-title"
            variant="h1"
            color="textPrimary"
            align="center"
          >
            {title}
          </Typography>
          <div className="page-date">
            {date && <p>{new Date(date).toDateString()}</p>}
          </div>
          <div className="page-image">
            {image && image.publicURL && (
              <img alt={"Some"} src={image.publicURL} width="100%" />
            )}
          </div>
          <div className="page-markdown">
            {content && <MDReactComponent text={content} />}
          </div>
          <div className="page-actions">
            <LikeButton strapiId={strapiId} type={"Basic"} title={title} />
            <FavButton strapiId={strapiId} type={"Basic"} title={title} />
          </div>
        </Grid>
        <Grid item xs={12} sm={5} lg={3} className="page-detail-sidebar"></Grid>
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    strapiBasic(id: { eq: $id }) {
      id
      strapiId
      title
      content
      date
      image {
        publicURL
        prettySize
      }
      fields {
        slug
      }
    }
  }
`
