import React from "react"
import clsx from "clsx"

// @Material UI
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Badge from "@material-ui/core/Badge"

// @Icons
import IconLike from "@material-ui/icons/ThumbUpAlt"
import IconFav from "@material-ui/icons/FavoriteBorder"
import IconFavActive from "@material-ui/icons/Favorite"
import CakeIcon from "@material-ui/icons/Cake"

// @Firebase
import firebase from "gatsby-plugin-firebase"

export function LikeButton(props) {
  const classes = useStyles()
  const { strapiId, type, title } = props
  const [count, setCount] = React.useState(0)
  const [active, setActive] = React.useState(false)
  const [authSession, setAuthSession] = React.useState(false)

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      setAuthSession(firebase.auth().currentUser)
    })
  })

  React.useEffect(() => {
    if (authSession && strapiId) {
      firebase
        .firestore()
        .collection("ActionsLike")
        .doc(strapiId)
        .onSnapshot(snapshot => {
          if (snapshot && snapshot.exists) {
            setCount(snapshot.data().count)
            if (
              snapshot.data().actioners &&
              snapshot.data().actioners.includes(authSession.email)
            ) {
              setActive(true)
            } else {
              setActive(false)
            }
          }
        })
    }
  }, [authSession, strapiId])

  const pressed = async () => {
    if (authSession) {
      let { email } = authSession
      const userDocument = await firebase
        .firestore()
        .collection("ActionsLike")
        .doc(strapiId)
        .get()

      if (!userDocument || !userDocument.exists) {
        await firebase
          .firestore()
          .collection("ActionsLike")
          .doc(strapiId)
          .set({
            count: firebase.firestore.FieldValue.increment(!active ? 1 : -1),
            type: type,
            title: title,
            actioners: !active
              ? firebase.firestore.FieldValue.arrayUnion(email)
              : firebase.firestore.FieldValue.arrayRemove(email),
            _updated: firebase.firestore.Timestamp.fromDate(new Date()),
          })
      } else {
        await firebase
          .firestore()
          .collection("ActionsLike")
          .doc(strapiId)
          .update({
            count: firebase.firestore.FieldValue.increment(!active ? 1 : -1),
            actioners: !active
              ? firebase.firestore.FieldValue.arrayUnion(email)
              : firebase.firestore.FieldValue.arrayRemove(email),
            _updated: firebase.firestore.Timestamp.fromDate(new Date()),
          })
      }
    }
  }

  return (
    <Button className={classes.btn} onClick={pressed}>
      <Badge badgeContent={count} color="primary">
        <div className={"icon-wrapper"}>
          <IconLike
            className={"icon"}
            htmlColor={active ? "#365eab" : "gray"}
          />
        </div>
      </Badge>
    </Button>
  )
}

export function FavButton(props) {
  const classes = useStyles()
  const { strapiId, type, title } = props
  const [count, setCount] = React.useState(0)
  const [active, setActive] = React.useState(false)
  const [authSession, setAuthSession] = React.useState(false)

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      setAuthSession(firebase.auth().currentUser)
    })
  })

  React.useEffect(() => {
    if (authSession) {
      firebase
        .firestore()
        .collection("ActionsHeart")
        .doc(strapiId)
        .onSnapshot(snapshot => {
          if (snapshot && snapshot.exists) {
            setCount(snapshot.data().count)
            if (
              snapshot.data().actioners &&
              snapshot.data().actioners.includes(authSession.email)
            ) {
              setActive(true)
            } else {
              setActive(false)
            }
          }
        })
    }
  }, [authSession, strapiId])

  const pressed = async () => {
    if (authSession) {
      let { email } = authSession
      const userDocument = await firebase
        .firestore()
        .collection("ActionsHeart")
        .doc(strapiId)
        .get()

      if (!userDocument || !userDocument.exists) {
        await firebase
          .firestore()
          .collection("ActionsHeart")
          .doc(strapiId)
          .set({
            count: firebase.firestore.FieldValue.increment(!active ? 1 : -1),
            type: type,
            title: title,
            actioners: !active
              ? firebase.firestore.FieldValue.arrayUnion(email)
              : firebase.firestore.FieldValue.arrayRemove(email),
            _updated: firebase.firestore.Timestamp.fromDate(new Date()),
          })
      } else {
        await firebase
          .firestore()
          .collection("ActionsHeart")
          .doc(strapiId)
          .update({
            count: firebase.firestore.FieldValue.increment(!active ? 1 : -1),
            actioners: !active
              ? firebase.firestore.FieldValue.arrayUnion(email)
              : firebase.firestore.FieldValue.arrayRemove(email),
            _updated: firebase.firestore.Timestamp.fromDate(new Date()),
          })
      }
    }
  }

  return (
    <Button className={classes.btn} onClick={pressed}>
      <Badge badgeContent={count} color="primary">
        <div className={"icon-wrapper"}>
          {active ? (
            <IconFavActive className={"icon"} htmlColor={"#d5392d"} />
          ) : (
            <IconFav className={"icon"} htmlColor={"gray"} />
          )}
        </div>
      </Badge>
    </Button>
  )
}

export function BirthdayButton({ id, title, type, period }) {
  const classes = useStyles()
  const [count, setCount] = React.useState(0)
  const [active, setActive] = React.useState(false)
  const [authSession, setAuthSession] = React.useState(false)
  const [celebration, setCelebration] = React.useState(false)

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      setAuthSession(firebase.auth().currentUser)
    })
  })

  React.useEffect(() => {
    if (authSession) {
      const subscriber = firebase
        .firestore()
        .collection("ActionsBirthday")
        .doc(period)
        .collection(id)
        .doc("summary")
        .onSnapshot(snapshot => {
          if (snapshot && snapshot.exists) {
            if (
              snapshot.data().actioners &&
              snapshot.data().actioners.includes(authSession.email)
            ) {
              setActive(true)
            } else {
              setActive(false)
            }
          }
        })
      return () => subscriber()
    }
  }, [authSession])

  const pressed = async () => {
    let { email } = authSession

    if (!active && !celebration) {
      setCelebration(true)
      setTimeout(function () {
        setCelebration(false)
      }, 1000)
    }

    const userDocument = await firebase
      .firestore()
      .collection("ActionsBirthday")
      .doc(period)
      .collection(id)
      .doc("summary")
      .get()

    if (!userDocument || !userDocument.exists) {
      await firebase
        .firestore()
        .collection("ActionsBirthday")
        .doc(period)
        .collection(id)
        .doc("summary")
        .set({
          count: firebase.firestore.FieldValue.increment(!active ? 1 : 0),
          actioners: !active
            ? firebase.firestore.FieldValue.arrayUnion(email)
            : firebase.firestore.FieldValue.arrayRemove(email),
          _updated: firebase.firestore.Timestamp.fromDate(new Date()),
        })
    } else {
      await firebase
        .firestore()
        .collection("ActionsBirthday")
        .doc(period)
        .collection(id)
        .doc("summary")
        .update({
          count: firebase.firestore.FieldValue.increment(!active ? 1 : -1),
          actioners: !active
            ? firebase.firestore.FieldValue.arrayUnion(email)
            : firebase.firestore.FieldValue.arrayRemove(email),
          _updated: firebase.firestore.Timestamp.fromDate(new Date()),
        })
    }
  }

  return (
    <Button
      className={clsx(classes.btn, classes.wrapperCake)}
      onClick={pressed}
    >
      <Badge badgeContent={count} color="primary">
        <CakeIcon
          htmlColor={active ? "#365eab" : "#888888"}
          className={classes.cake}
        />
      </Badge>
    </Button>
  )
}

const useStyles = makeStyles(theme => ({
  btn: {
    height: "50px",
    width: "50px",
    border: "none",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& .icon": {
      fontSize: 40,
    },
  },
  wrapperCake: {
    backgroundColor: "#dddddd",
    height: 60,
    width: 60,
    borderRadius: 30,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    margin: "0 auto",
  },
  cake: {
    fontSize: 45,
  },
}))
